<template>
  <div class="goodDetail">
    <div class="bg1">123</div>
    <div class="goodCont">
      <div class="tohome">
        当前位置：<span style="cursor: pointer" @click="toHome">首页</span>＞<span>商品详情</span>
      </div>
      <div class="goodInfo">
        <div class="leftInfo">
          <img :src="goodDetail.imgaddr" alt="" />
        </div>
        <div class="rightInfo">
          <div class="goodName">
            {{ goodDetail.title }}
          </div>
          <div class="goodSpec">生产厂家：{{ goodDetail.comm }}</div>
          <div class="goodSpec">规格：{{ goodDetail.spec }}</div>
          <div class="goodSpec">
            {{ goodDetail.wh }}
          </div>
          <div class="goodInst">
            <div v-if="goodDetail.id == 1">
              感冒灵颗粒说明书<br />
              请仔细阅读说明书并按说明便用或在药师指导下购买和使用<br />
              【药品名称】通用名称：感冒灵颗粒<br />
              汉语拼音：Ganmaoling Keli<br />
              【成 份】三叉苦、金盏银盘、野菊花、岗梅、咖啡因、对乙酰氨基酚、马来酸氯苯那敏、薄荷素油。辅料为蔗糖。<br />
              【性 状】本品为浅棕色至深棕色的颗粒;味甜、微苦。<br />
              【规 格】每袋装10克（含对乙酰氮基酚0.2克、咖啡因4毫克、马来酸氯苯那敏4毫克）<br />
              【用法用量】开水冲眼。一次10克（1袋），一日3次。<br />
              【不良反应】偶见皮疹、荨麻疹、药热及粒细胞战少;可见困伦、嗜睡、虚弱感；长期大量用药会导致肝肾功能异常。<br />
              【禁 忌】严重肝肾功能不全者装用"<br />
              【注意事项】1.忌烟、酒及辛辣、生冷、油腻食物。<br />
              2.不宜在服药期间同时服用滋补性中成药。<br />
              3.本品含对乙酰氨基裨、马来酸氯苯那敏、咖啡因。职用水只们间不得饮酒或含有酒清的饮料;不能同时服用与本品成份相似的其他放成的团、肾功能不全者慎用;膀胱颈梗阻、甲状腺功能亢进、青光眼、赢血
              和前列照肥大者慎用;孕妇及哺乳期妇女慎用;服药期间不得驾驶机、车、船从高空作业、机械作业及操作精密仪器。<br />
              4.脾胃虚寒,症见腹痛、喜暖、泄泻者慎用。<br />
              5.糖尿病患者及有心脏病等慢性病严重者应在医师指导下服用。<br />
              6.儿童、年老体弱者应在医师指导下服用。<br />
              7.服药3天后症状无改善，或症状加重，或出现新的严重症状如胸闷、心悸等应立即停药,并去医院就诊。<br />
              8.对本品过敏者禁用,过敏体质者慎用。9.本品性状发生改变时禁止使用。<br />
              10.儿童必须在成人监护下使用。<br />
              11.请将本品放在儿童不能接触的地方。<br />
              12.如正在使用其他药品,使用本品前请咨询医师或药师。<br />
              【药物相互作用】<br />
              1.与其他解热镇痛药并用,有增加仔毒性的危险。<br />
              2.如与其他药物同时使用可能会发生药物相互作用，详情请咨询医师或药师。<br />
              【贮 藏】密封。<br />
              【包 装】复合膜；每盒装9袋。<br />
              【有 效 期】24个月<br />
              【执行标准】国家药品标准WS3-B-1660-93-2019【批准文号】国药准字Z44021940<br />
              【说明书修订日期】2020年12月01日<br />
              【上市许可持有人】<br />
              名 称:华润三九医药股份有限公司<br />
              注册地址:深圳市龙华区观湖街道观澜高新园区观清路1号<br />
              邮政编码:518110<br />
              电话号码:（0755)83360999<br />
              网址:www.999.com.cn<br />
              【生产企业】<br />
              企业名称:华润三九(枣庄)药业有限公司<br />
              生产地址:枣庄高新技术产业开发区广润路99号<br />
              如有问题可与上市许可持有人联系<br />
            </div>
            <div v-if="goodDetail.id == 2">
              藿香正气水说明书<br />
              请仔细阅读说明书并按说明使用或在药师指导下购买和使用<br />
              本品含生半夏<br />
              【药品名称】<br />
              通用名称：藿香正气水<br />
              汉语拼音：Huoxiang Zhengqi Shui<br />
              【成份】苍术、陈皮、厚朴（姜制）、白芷、茯苓、大腹皮、生半夏、甘草浸膏、广藿香油、紫苏叶油;辅料为干姜、乙醇。<br />
              【性状】本品为深棕色的澄清液体（贮存略有沉淀）:味辛、苦。<br />
              【功能主治】解表化湿、理气和中。用于外感风寒、内伤湿滞或夏伤暑湿所致的感冒,症见头痛昏重、胸膈痞闷、院腹胀痛、呕吐泄泻;胃肠型感冒见上述证候者。<br />
              【规格】每支装10毫升<br />
              【用法用量】口服。一次5～10毫升,一日2次,用时摇匀。<br />
              【不良反应】<br />
              1.本品可能引起恶心、呕吐、皮疹、瘙痒、头晕、潮红、心悸等。<br />
              2.本品含乙醇(酒精)，有服用本品后出现过敏性休克的病例:乙醇（酒精）与头孢菌素类(如头孢氨苄、头孢呋辛、头孢他啶等)、甲硝唑、替硝唑、酮康唑、呋喃唑酮等药联合使用、可出现双硫仑样反应（主要表现为颜面潮红、头痛、恶心、呕吐、心悸、血压下降、胸闷、胸痛、气短、呼吸困难、休克等）；有过量服用本品出现抽搐的病例。<br />
              【禁忌】<br />
              1.对本品及所含成份过敏者禁用。<br />
              2.酒精过敏者禁用。<br />
              【注意事项】<br />
              1忌烟、酒及辛辣、生冷、油腻食物,饮食宜清淡。<br />
              2.不宜在服药期间同时服用滋补性中药。<br />
              3.有高血压、心脏病、肝病、糖尿病、肾病等慢性病严重者应在医师指导下服用。<br />
              4.不建议儿童、孕妇及哺乳期妇女使用。年老体弱者应在医师指导下服用。<br />
              5.吐泻严重者应及时去医院就诊。<br />
              6.本品含乙醇（酒精）40%～50%，服药期间不得与头孢菌素类（如头孢氨苄、头孢呋辛、头孢他啶等）、甲硝唑、替硝唑、酮康唑、呋喃唑酮等药联合使用，以免导致双硫仑样反应；此外，服药后不得驾驶机、车、船、从事高空作业、机械作业及操作精密仪器。<br />
              7.本品含生半夏,应严格按用法用量服用,不宜过量或长期服用。用药后如出现说明书描述的不良反应或其他不适时应停药,症状严重者应及时去医院就诊。<br />
              8.服药3天症状无缓解,应去医院就诊。<br />
              9.对本品及酒精过敏者禁用,过敏体质者慎用。<br />
              10.本品性状发生改变时禁止使用。<br />
              11.儿童必须在成人监护下使用。<br />
              12.请将本品放在儿童不能接触的地方。<br />
              13.如正在使用其他药品,使用本品前请咨询医师或药师。<br />
              【药物相互作用】<br />
              1.本品含乙醇(酒精)40%～50%,与头孢类药物或易产生双硫仑反应的药物合用可使血中乙酰醛浓度上升,出现双硫仑反应,故服药期问不得与头孢菌素类(如头孢氨苄、头孢味辛、头孢他啶等)、甲硝唑、替硝唑、酮康唑、呋南唑酮等药联合使用,以免导致双硫仑样反应。<br />
              2.如与其他药物同时使用可能会发生药物相互作用,详情请咨询医师或药师。<br />
              【贮藏】密封<br />
              【包装】口服液体药用高密度聚乙烯瓶,10毫×10支。<br />
              【有效期】24个月<br />
              【执行标准】《中华人民共和国药典》2020年版一部<br />
              【批准文号】国药准字Z51022378<br />
              【说明书修订日期】2020年12月30日<br />
              【上市许可持有人】四川天府康达药业集团府庆制药有限公司<br />
              注册地址:仁寿县视高经济开发区桂花大道6号<br />
              【生产企业】<br />
              生产企业:四川天府康达药业集团府庆制药有限公司<br />
              生产地址:四川省仁寿县视高经济开发区桂花大道6号<br />
              邮政编码:620564<br />
              电话号码:028-36061285<br />
              传真号码:028-36066080<br />
              网址:www.sctfkd.com<br />
              如有问题可与生产企业联系<br />
            </div>
            <div v-if="goodDetail.id == 3">
              小柴胡颗粒说明书<br/>
              请仔细阅读说明书并按说明使用或在药师指导下购买和使用<br/>
              【药品名称】<br/>
              通用名称:小柴胡颗粒<br/>
              汉语拼音:Xiaochaihu Keli<br/>
              【成 份】柴胡、黄芩、姜半夏、党参、生姜、甘草、大枣。辅料为蔗糖。<br/>
              【性 状】本品为黄色至棕褐色的颗粒；味甜。<br/>
              【动能主治】解表散热，疏肝和胃。用于外感病，邪犯少阳证，症见寒热往来、胸胁苦满、食欲不振、心烦出呕、口苦咽干。<br/>
              【规 格】每袋装10克。<br/>
              【用法用量】开水冲服。一次1～2袋,一日3次。<br/>
              【不良反应】尚不明确。<br/>
              【禁 忌】尚不明确。<br/>
              【注意事项】<br/>
              1.忌烟、酒及辛辣、生冷、油腻食物。<br/>
              2.不宜在服药期间同时服用滋补性中成药。<br/>
              3.风寒感冒者不适用。<br/>
              4.糖尿病患者及有高血压、心脏病、肝病、肾病等慢性病严重者应在医师指导下服用。<br/>
              5.儿童、孕妇、哺乳期妇女、年老体弱者应在医生指导下服用。<br/>
              6.发热体温超38.5℃的患者，应去医院就诊。<br/>
              7.服药三天症状无缓解，应去医院就诊。<br/>
              8.对本品过敏者禁用，过敏体质者慎用。<br/>
              9.本品性状发生改变时禁止使用。<br/>
              10.儿童必须在成人监护下使用。<br/>
              11.请将本品放在儿童不能接触的地方。<br/>
              12.如正在使用其他药品，使用本品前请咨询医师或者药师。<br/>
              【药物相互作用】如与其他药物同时使用可能会生药物相互作用，详情请咨询医师或药师。<br/>
              【贮 藏】密封。<br/>
              【包 装】复合铝袋包装,（1）每盒10袋;（2）每盒6袋;（3）每盒20袋;（4）每盒1袋<br/>
              【有 效 期】24个月<br/>
              【执行标准】《中国药典》2020年版—部<br/>
              【批准文号】国药准字Z44020211<br/>
              【说明书修订日期】2020年12月30日<br/>
              【药品上市许可持有人】<br/>
              企业名称:广州白云山光华制药股份有限公司<br/>
              注册地址:广州市海珠区南石路1号<br/>
              【生产企业】<br/>
              企业名称:广州白云山光华制药股份有限公司<br/>
              生产地址:广州市萝岗区馆龙柯岭路2号<br/>
              邮政编码:510285<br/>
              电话号码:<br/>
              ①客广服务咨询电话:8009992828<br/>
              ②质量专线:020-84352975<br/>
              传真号码:020-84352975<br/>
              网址: www.gzghyy .com<br/>
              如有问题可与生产企业联系<br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodList: [
        {
          id: 1,
          imgaddr: require("@imgs/1.png"),
          title: "感冒灵颗粒(999)",
          comm: "华润三九医药股份有限公司",
          spec: "10g*9袋",
          wh: "【批准文号】国药准字Z44021940",
          instructions: '',
        },
        {
          id: 2,
          imgaddr: require("@imgs/2.png"),
          title: "藿香正气水",
          comm: "四川天府康达药业集团府庆制药有限公司",
          spec: "10ml*10支",
          wh: "【批准文号】国药准字Z51022378",
        },
        {
          id: 3,
          imgaddr: require("@imgs/3.png"),
          title: "小柴胡颗粒(有糖)",
          comm: "广州白云山光华制药股份有限公司",
          spec: "10g*10袋",
          wh: "【批准文号】国药准字Z44020211",
        },
      ],
      id: "",
      goodDetail: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      var goodList = this.goodList.filter((v) => {
        return v.id == this.id;
      });
      this.goodDetail = goodList[0];
    }
    console.log(this.goodDetail);
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.goodDetail {
  .bg1 {
    width: 195px;
    height: 50px;
  }

  .goodCont {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    min-height: 449px;

    .tohome {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      text-align: left;
    }

    .goodInfo {
      display: flex;
      margin-top: 40px;

      .leftInfo {
        width: 200px;
        height: 200px;
        margin-right: 20px;

        img {
          width: 200px;
          height: 200px;
        }
      }

      .rightInfo {
        text-align: left;

        .goodName {
          font-size: 24px;
          font-weight: bold;
        }

        .goodSpec {
          font-size: 14px;
          color: #4d4d4d;
          margin-top: 10px;
        }

        .goodInst {
          font-size: 18px;
          color: #4d4d4d;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>